.policies {
  margin: 120px 4rem;
  text-align: left;
  color: $primary;
  p {
    margin: 1rem auto;
  }
  .backHome {
    cursor: pointer;
    svg {
      color: red;
      font-size: 32px;
    }
    svg:hover {
      color: brown;
    }
  }
}

@media (min-width: 1200px) {
  .policies {
    width: 1140px;
    margin: 2rem auto;
  }
}
