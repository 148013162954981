.influencers {
  margin: 2rem auto;
  padding: 0 1rem 0 1.5rem;
  h3 {
    font-size: 28px;
    font-weight: 600;
    color: $primary;
  }
  .applyButton {
    width: 220px;
    margin: 2rem auto;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      display: flex;
      align-items: center;
      transition: all 0.4s;
      font-size: 14px;
      font-weight: 600;
      background-image: linear-gradient(
        to right,
        #5340ff 0,
        #ff005e 51%,
        #5340ff 100%
      );
      background-size: 200% auto;
      color: white;
      border-radius: 3rem;
      padding: 0.8rem 2rem;
      svg {
        margin-left: 6px;
        font-size: 1.3rem;
      }
    }
    a:hover {
      transition: all 0.4s;
      background-position: right center;
    }
  }
  .tabs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    span {
      margin-top: 1rem;
      background: #e2e2e2;
      color: $primary;
      border-radius: 22px;
      font-size: 13px;
      font-weight: 300;
      padding: 10px 12px;
      cursor: pointer;
    }
  }
  .container {
    margin: 2rem auto;
    .image-wrapper {
      width: 110px;
      height: 110px;
      img {
        width: 100%;
      }
    }
  }
}

@media (min-width: 812px) {
}
@media (min-width: 1200px) {
  .influencers {
    width: 1140px;
  }
}
