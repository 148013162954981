#token {
  scroll-margin-top: 30px;
}

.token {
  color: $primary;
  padding: 20px;
  margin: 2rem auto;

  .tokenHead {
    h3 {
      font-size: 36px;
      font-weight: 600;
      margin: 10px auto;
    }
  }

  .tokenContainer {
    .tokenContent {
      h6 {
        font-size: 20px;
        font-weight: 500;
        margin: 10px auto;
      }
      p {
        margin-top: 3rem;
        margin-bottom: 1rem;
        font-size: 14px;
      }
      ul {
        margin: 0 auto;
        width: 300px;
        li {
          display: flex;
          align-items: center;
          font-size: 14px;
          margin-bottom: 1rem;
        }
      }
    }
    .tokenChart {
      width: 285px;
      margin: 2rem auto;
    }
    .tokenLabels {
      width: 315px;
      margin: 0 auto;
      li {
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        font-size: 16px;
        svg {
          margin-right: 10px;
          font-size: 20px;
        }
      }
    }
  }

  .fundraise {
    margin-top: 80px;
    .fundraiseHead {
      h4 {
        text-align: center;
        font-size: 28px;
        font-weight: 500;
      }
      .line {
        margin-top: 1rem;
      }
    }
    .fundraiseCards {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .card {
        position: relative;
        width: 245px;
        border-radius: 10px;
        margin: 1rem;
        text-align: center;
        color: #fff;
        background: $primary;
        p {
          font-size: 14px;
          font-weight: 400;
        }
        .cardHead {
          background: linear-gradient(to right, #5b45bf, #1c7bf7);
          padding: 1rem 0;
          border-radius: 10px 10px 0 0;
          h5 {
            line-height: 0;
            margin: 1rem;
            font-size: 20px;
            font-weight: 600;
          }
        }
        .cardContent {
          padding: 1rem;
          h6 {
            line-height: 0;
            font-size: 20px;
            font-weight: 600;
            margin: 1rem 0 2rem 0;
          }
          p {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}

@media (min-width: 812px) {
  .token {
    .tokenContainer {
      .chartAndLabels {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .tokenHead {
      h3 {
        font-size: 42px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .token {
    width: 1140px;
    text-align: left;
    .tokenHead {
      width: 260px;
      margin-top: 50px;
    }
    .tokenContainer {
      display: flex;
      justify-content: space-between;
      .tokenContent {
        width: 380px;
        ul {
          margin: 0;
          li {
            display: flex;
            align-items: center;
            font-size: 14px;
            margin-bottom: 1rem;
          }
        }
      }

      .chartAndLabels {
        .tokenChart {
          width: 315px;
          margin-right: 4rem;
        }
        .tokenLabels {
          width: 315px;
          margin: 0 auto;
          li {
            margin-bottom: 1rem;
            display: flex;
            align-items: center;
            font-size: 16px;
            svg {
              margin-right: 10px;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}
