#team {
  scroll-margin-top: 130px;
}
.team {
  h3 {
    font-size: 28px;
    font-weight: 600;
    color: $primary;
    margin-top: 120px;
    margin-bottom: -1rem;
  }
  .profiles {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .profile {
    width: 280px;
    display: flex;
    justify-content: space-around;
    margin: 1.5rem auto;
    .profileName {
      width: 175px;
    }
    .profileImg {
      width: 115px;
      position: relative;
      img {
        width: 100%;
        height: auto;
        border-radius: 50%;
      }
      a {
        display: block !important;
        position: absolute;
        width: 38px;
        height: 38px;
        background-color: #0077b5;
        border-radius: 50%;
        z-index: 45;
        top: 0;
        right: 0;

        svg {
          color: #fff;
          padding: 10px;
        }
      }
      a:hover {
        background-color: #035c8b;
      }
    }
    .profileName {
      margin-left: 1.5rem;
      text-align: left;
      font-size: 15px;
      font-weight: 300;
      color: #000659;

      .titr {
        font-size: 13px;
      }
    }
  }
}

@media (min-width: 812px) {
}
@media (min-width: 1200px) {
  .team {
    width: 1140px;
    margin: 120px auto;
    .last2 {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .profiles {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}
