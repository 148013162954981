header {
  margin: 0 auto;
  .header-top {
    display: none;
  }
  .navbar {
    width: 100%;
    background: white;

    .menu {
      display: none;
      a {
        color: $primary;
        font-size: 15px;
        font-weight: 700;
      }
      a:hover {
        color: $secondary;
      }
    }

    .headerLogo {
      width: 180px;
      height: auto;
      margin-top: 5px;
    }
    .buyBtn {
      .buyPromo {
        width: 130px;
        display: flex;
        align-items: center;
        transition: all 0.4s;
        font-size: 14px;
        background-image: linear-gradient(
          to right,
          #5340ff 0,
          #ff005e 51%,
          #5340ff 100%
        );
        background-size: 200% auto;
        color: white;
        border-radius: 3rem;
        padding: 0.8rem 2rem;
        svg {
          margin-right: 6px;
          font-size: 1.2rem;
        }
      }
      .buyPromo:hover {
        transition: all 0.4s;
        background-position: right center;
        color: white;
      }
    }
  }

  .mobilHeader {
    position: fixed;
    top: 0;
    right: 0;
    padding: 20px 0;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    box-shadow: -0px 15px 10px -15px rgba(97, 97, 97, 0.4);
    z-index: 999;
    a {
      margin-left: 2rem;
    }
    .barIcon {
      font-size: 1.5rem;
      padding: 0.5rem;
      margin-right: 1rem;
      border-radius: 1rem;
      cursor: pointer;
    }

    .barIcon:hover {
      background: $secondary;
      color: white;
    }
  }

  .mobilMenu {
    background-color: rgb(226, 204, 245);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    z-index: 999;
    .closeBtn {
      background-color: white;
      border-radius: 50%;
      position: absolute;
      color: #ff005e;
      top: 10px;
      right: 10px;
      font-size: 2rem;
      cursor: pointer;
    }
    .header-mobil-top {
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    ul {
      margin-left: 2rem;
      li {
        margin-top: 1rem;
      }
    }
    a {
      color: $primary;
      font-size: 15px;
      font-weight: 700;
    }
    a:hover {
      color: $secondary;
    }
  }
}

@media (min-width: 1200px) {
  header {
    width: 1140px;

    .navbar {
      width: 1140px;
      margin: 0 auto;
      .menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 0 auto;
        a {
          margin-right: 15px;
        }
      }
    }
    .sticky {
      width: 100%;
      z-index: 999;
      position: fixed;
      top: 0;
      left: 0;
      box-shadow: -0px 15px 10px -15px rgba(97, 97, 97, 0.4);
      .menu {
        width: 1140px;
      }
    }
    .header-top {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .community {
        display: flex;
        margin: 0 1rem;
        align-items: center;
        justify-content: center;
        button {
          margin-top: 0;
        }
      }
    }
    .mobilHeader {
      display: none;
    }
  }
}
