.stm_wave_roadmap {
  padding-left: 0px;
  margin-left: -10px;
  overflow-x: auto;
  padding-right: -10px;
}
.stm_wave_roadmap__road {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.stm_wave_roadmap__road_top {
  height: 150px;
}
.stm_wave_roadmap__road_top .stm_wave_roadmap__road_content:not(:first-child) {
  margin-left: 50px;
}
.stm_wave_roadmap__road_top .stm_wave_roadmap__road_anchor {
  position: absolute;
  top: 8px;
  left: 0;
  width: 8px;
  height: 8px;
  background-color: #1c7bf7;
  border-radius: 50%;
  z-index: 99;
}
.stm_wave_roadmap__road_top .stm_wave_roadmap__road_anchor:before {
  content: '';
  display: block;
  position: absolute;
  top: 11px;
  left: 3px;
  width: 1px;
  height: 160px;
  border: 1px dashed rgba(255, 255, 255, 0.5);
}
.stm_wave_roadmap__road_top .stm_wave_roadmap__road_anchor:after {
  content: '';
  display: block;
  position: absolute;
  top: 174px;
  left: -5px;
  width: 19px;
  height: 19px;
  border: 7px solid #fff;
  border-radius: 50%;
}
.stm_wave_roadmap__road_bottom {
  padding: 73px 9.5% 0 13%;
}
.stm_wave_roadmap__road_bottom .stm_wave_roadmap__road_content {
  width: 30%;
}
.stm_wave_roadmap__road_bottom .stm_wave_roadmap__road_anchor {
  position: absolute;
  top: 8px;
  left: 0;
  width: 8px;
  height: 8px;
  background-color: #1c7bf7;
  border-radius: 50%;
  z-index: 99;
}
.stm_wave_roadmap__road_bottom .stm_wave_roadmap__road_anchor:before {
  content: '';
  display: block;
  position: absolute;
  bottom: 11px;
  left: 3px;
  width: 1px;
  height: 75px;
  border: 1px dashed rgba(255, 255, 255, 0.5);
}
.stm_wave_roadmap__road_bottom .stm_wave_roadmap__road_anchor:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 92px;
  left: -5px;
  width: 19px;
  height: 19px;
  border: 7px solid #fff;
  border-radius: 50%;
}
.stm_wave_roadmap__road_content {
  width: 19%;
}
.stm_wave_roadmap__road_date {
  position: relative;
  padding-left: 23px;
  font-size: 18px;
}
.stm_wave_roadmap__road_description {
  padding-left: 23px;
  font-size: 13px;
  line-height: 24px;
  opacity: 0.5;
}
.stm_wave_roadmap__wave {
  position: relative;
  width: 85%;
  height: 54px;
  overflow: hidden;
}
.stm_wave_roadmap__wave svg {
  width: 973px;
}
.stm_wave_roadmap__wave .unfilled path {
  fill: rgba(255, 255, 225, 0.25);
}
.stm_wave_roadmap__wave .unfilled.shadow_wave path {
  fill: #fff;
}
.stm_wave_roadmap__wave .filled {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 54px;
  overflow: hidden;
}
.stm_wave_roadmap__wave .filled svg {
  position: absolute;
  top: 0;
  left: 0;
}
