.partners {
  width: 350px;
  margin: 0 auto;
  h3 {
    font-size: 28px;
    color: $primary;
    font-weight: 600;
    margin-top: 2rem;
  }
  .line {
    margin: 0.7rem auto;
  }

  .container {
    padding: 10px;
    margin: 2rem auto;
  }

  .image-wrapper {
    width: 180px;
    height: auto;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .slick-track {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin: auto;
      width: 100%;
    }
  }
}
@media (min-width: 812px) {
  .partners {
    width: 700px;
    margin: 0 auto;
  }
  .image-wrapper {
    width: 190px;
    height: auto;
  }
}
@media (min-width: 1200px) {
  .partners {
    width: 1140px;
    margin: 0 auto;
  }
}
