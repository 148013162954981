.onMedia {
  margin: 2rem auto;
  padding: 0 1rem 0 1.5rem;
  h3 {
    font-size: 28px;
    color: $primary;
    font-weight: 600;
    margin-top: 2rem;
  }
  .line {
    margin: 0.7rem auto;
  }
  .container {
    padding: 10px;
    margin-bottom: 3rem;
  }

  .image-wrapper {
    width: 120px;
    height: 70px;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .slick-slide img {
    margin: auto;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .onMedia {
    width: 1140px;
  }
}
