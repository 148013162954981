#roadmap {
  scroll-margin-top: 110px;
}
.roadmap {
  margin: 0 auto;
  color: #fff;
  background: $secondary;
  padding-bottom: 80px;
  h3 {
    margin-top: 2rem;
  }
  .roadmapWrapper {
    .roadmap-web {
      display: none;
      .roadmapParts1 {
        display: flex;
        justify-content: center;

        .roadmapPart {
          width: 250px;
          margin: 2rem auto;
          border: 1px dashed white;
          padding: 0.4rem;
          h5 {
            margin: 10px;
            font-size: 18px;
          }
          ul {
            li {
              font-size: 14px;
              text-align: left;
              svg {
                margin-right: 5px;
                margin-top: 2px;
              }
            }
          }
        }
      }
      .roadmapParts2 {
        width: 700px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        .roadmapPart {
          width: 250px;
          margin: 2rem auto;
          border: 1px dashed white;
          padding: 0.4rem;
          h5 {
            margin: 10px;
            font-size: 18px;
          }
          ul {
            li {
              font-size: 14px;
              text-align: left;
              svg {
                margin-right: 5px;
                margin-top: 2px;
              }
            }
          }
        }
      }
    }
    .roadmap-mobil {
      .mobil-parts {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .mobilPart {
          width: 250px;
          margin: 2rem 2.2rem;
          border: 1px dashed white;
          padding: 0.4rem;
          h5 {
            margin: 10px;
            font-size: 18px;
          }
          ul {
            li {
              font-size: 14px;
              text-align: left;
              svg {
                margin-right: 5px;
                margin-top: 2px;
              }
            }
          }
        }
      }
    }

    .roadQuarters {
      display: flex;
      justify-content: center;
      align-items: center;

      .quarter {
        padding: 10px 20px;
        width: 130px;
        position: relative;
        border-top: 2px solid white;
        border-bottom: 2px solid white;
        font-weight: 600;
      }
      .quarter:nth-child(even)::before {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        width: 8;
        height: 8;
        border-style: solid;
        border-width: 10px 10px;
        border-color: white transparent transparent transparent;
      }
      .quarter:nth-child(odd)::before {
        content: '';
        position: absolute;
        top: -11px;
        left: 50%;
        width: 8;
        height: 8;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid white;
      }
      .qfirst {
        border-radius: 20px 0 0 20px;
        background: #df0c7c;
        border-left: 2px solid white;
      }
      .qsecond {
        background: rgb(7, 177, 21);
      }
      .qthird {
        background: rgb(131, 9, 202);
      }
      .qthird-2 {
        background: rgb(151, 104, 178);
      }
      .qfourth {
        background: rgb(136, 145, 6);
      }
      .qfifth {
        background: rgb(5, 36, 172);
        border-radius: 0 20px 20px 0;
        border-right: 2px solid white;
      }
    }
  }
  h3 {
    font-size: 42px;
    font-weight: 600;
    padding-top: 3rem;
  }
}

@media (min-width: 1200px) {
  .roadmap {
    .roadmapWrapper {
      width: 1040px;
      margin: 0 auto;
      z-index: 3;
      .roadmap-web {
        display: block;
      }
      .roadmap-mobil {
        display: none;
      }
    }
  }
}
