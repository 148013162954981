.dropdown {
  font-size: 12px;
  button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.4rem 1rem;
    margin-top: 1rem;
    color: $primary;
    font-size: 12px;
  }
  button:hover {
    background: linear-gradient(to right, #5340ff 0, #ff005e 51%, #5340ff 100%);
    color: white;
    border-radius: 3rem;
  }
  .dropMenu {
    position: absolute;
    background-color: white;
    padding: 1rem;
    z-index: 999;
    text-align: left;
    cursor: pointer;
    ul {
      margin: 0;
      li {
        margin-bottom: 1rem;
        a {
          color: $primary;
          font-size: 10px;
        }
        a:hover {
          color: $secondary;
        }
      }
    }
  }
}
