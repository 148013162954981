#features {
  scroll-margin-top: 110px;
}
.features {
  color: $primary;
  margin: 3rem auto;

  .featuresHead {
    display: flex;
    flex-direction: column;
    h3 {
      font-size: 36px;
      font-weight: 600;
      margin-top: 20px;
      margin-bottom: -1rem;
    }
  }
}

//Card
.cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: -2rem;
  margin-bottom: 3rem;

  .card {
    width: 280px;
    height: 120px;
    margin: 1rem;
    padding: 2rem;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    text-align: left;
    .cardIcon {
      margin-right: 2rem;
      svg {
        font-size: 38px;
        font-weight: 500;
        color: $secondary;
      }
    }
    .cardContent {
      h5 {
        font-size: 15px;
        margin: 0 0 15px;
      }
      p {
        font-size: 14px;
        max-width: 210px;
      }
    }
  }
  .card:hover {
    background-image: linear-gradient(to top right, #5340ff, #ff005e);
    color: #fff;
    text-decoration: none;
    outline: none !important;
    transition: 0.3s ease;
    box-shadow: 3px 7px 15px rgba(69, 160, 239, 0.66);
    border-radius: 20px;
    svg {
      color: #fff;
    }
  }
}

@media (min-width: 812px) {
  .features {
    .featuresHead {
      display: grid;
      grid-template-columns: 1fr 1fr;

      h3 {
        font-size: 42px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .features {
    width: 1140px;
  }
}
