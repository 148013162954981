@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

//variables
@import 'variables';

//pages
@import './pages/home';
@import './pages/policies';

//Header
@import './header/header';
@import './header/community';
@import './header/selectLanguage';
@import './header/dropdown';

//Main
@import './main/introduction';
@import './main/introButton';
@import './main/about';
@import './main/howItWorks';
@import './main/features';
@import './main/wave';
@import './main/roadmap';
@import './main/token';
@import './main/team';
@import './main/partners';
@import './main/influencers';
@import './main/onmedia';

//Footer
@import './footer/footer';

html {
  scroll-behavior: smooth;
  scroll-padding-top: 30px;
}

body {
  font-family: Poppins, sans-serif;
  text-align: center;
  margin: 0;
  position: relative;
}
ul,
li,
p,
h1,
h2,
h3,
h4,
hr {
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.line {
  width: 60px;
  height: 3px;
  background: linear-gradient(to right, #5340ff, #ff005e);
  margin: 2rem auto;
}

.arrowIcon {
  color: $secondary;
  font-size: 22px;
  margin-right: 12px;
  min-width: 22px;
}

.floatingMenu {
  position: fixed;
  z-index: 999;
  right: 2rem;
  bottom: 2rem;
}
