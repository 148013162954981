footer {
  background: rgb(241, 241, 241);
  margin: 0;
  .footer {
    height: 100%;
    margin: 0 auto;
    padding: 1rem;
    color: $primary;

    h3 {
      font-size: 22px;
      font-weight: 500;
    }
    .logo {
      width: 300px;
      text-align: left;
      font-size: 14px;
      padding: 1rem;
      margin: 1rem auto;
      img {
        width: 60%;
        margin: 1rem auto;
      }
    }
    .recentPost {
      width: 300px;
      text-align: left;
      margin: 1rem auto;
      ul {
        margin-top: 1rem;
        li {
          margin: 1rem auto;

          a {
            color: $primary;
            font-size: 12px;
            margin-top: 1rem;
            line-height: 10px;
          }
          a:hover {
            text-decoration: underline;
          }
          svg {
            margin-right: 5px;
          }
        }
      }
    }
    .quickLinks {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 1rem;

      .linkbox {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
        ul {
          list-style-type: square;
          margin: 0 2rem;
          li {
            width: 90px;
            text-align: left;
            a {
              font-size: 12px;

              color: $primary;
            }
          }
        }
      }
    }
    .socialIcons {
      margin-top: 2rem;
      color: $secondary;
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        color: #a466fd;
        margin-right: 1rem;
      }
      a:hover {
        color: $secondary;
      }
      svg {
        font-size: 22px;
      }
    }
  }
  .policiesLinks {
    height: 60px;
    a {
      color: $primary;
      margin-right: 1rem;
      font-size: 12px;
      font-weight: 400;
    }
    a:hover {
      color: $secondary;
      text-decoration: underline;
    }
  }
  .copyright {
    font-size: 12px;
    padding-bottom: 2rem;
  }
}
@media (min-width: 812px) {
  .footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}
@media (min-width: 1200px) {
  .footer {
    width: 1140px;
    height: 100%;
    display: flex;
    justify-content: space-between;
  }
  .logo {
    margin-right: 1rem;
  }
  .recentPost {
    margin-right: 2rem;
  }
  .quickLinks {
    .linkbox {
      flex-direction: column;
      ul {
        margin-left: 2rem;
        margin-top: 0;
      }
    }
  }
}
