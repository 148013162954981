@import '../variables.scss';

.community {
  margin: 1rem auto;
  .socialIcons {
    margin-right: 2rem auto;
    a {
      color: $primary;
      margin: 0.5rem;
    }
  }
}
