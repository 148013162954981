.lang {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  .selectInput {
    background: #fff linear-gradient(180deg, #e1e0ff 0%, #fff 70%);
    color: rgb(82, 82, 82);
    transition: 0.3s ease;
    border: none;
    border-radius: 2px;
    width: 150px;
    padding: 6px;
    font-size: 13px;
    cursor: pointer;
  }
  .selectInput:hover {
    background: #e1e0ff;
    color: blue;
  }
}
