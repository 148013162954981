#home {
  scroll-margin-top: 40px;
}

.introduction {
  margin: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h1 {
    max-width: 200px;
    line-height: 40px;
    text-align: left;
    color: $secondary;
    font-weight: 600;
    font-size: 40px;
    margin-top: 2rem;
  }
  .introText {
    margin: 0 auto;
    p {
      width: 100%;
      max-width: 500px;
      margin-top: 20px;
      text-align: left;
      line-height: 30px;
      font-weight: 400;
      font-size: 14px;
      color: #283d5c;
    }
    .introHead {
      .marginZero {
        margin-top: 0;
      }
    }
  }
  .introImg {
    display: flex;
    justify-content: center;

    img {
      margin-top: 1rem;
      width: 100%;
      height: auto;
    }
  }
}
@media (min-width: 812px) {
  .introduction {
    .introText {
      display: flex;
      align-items: center;
      padding: 0 1rem;
      .introHead {
        margin-right: 1rem;
      }
    }
    .introImg {
      img {
        width: 85%;
        height: auto;
      }
    }
  }
}

@media (min-width: 1200px) {
  .introduction {
    margin: 2rem auto;
    width: 1140px;
    flex-direction: row;
    justify-content: space-between;
    .introText {
      display: block;
    }
    .introImg {
      img {
        width: 80%;
        height: auto;
      }
    }
  }
}

/* @media (min-width: 375px) {
  
}
@media (min-width: 812px) {
  
}

@media (min-width: 1200px) {

}
 */
