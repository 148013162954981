#aboutPromo {
  scroll-margin-top: 130px;
}

.about {
  color: $primary;
  margin: 3rem;
  padding-top: 2rem;
  .whatIs {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    .aboutVideo {
      position: relative;
      .aboutPlay {
        position: absolute;
        top: 45%;
        left: 45%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 75px;
        height: 75px;
        transition: all 0.4s;
        color: white;
        background-image: linear-gradient(
          to right,
          #5340ff 0,
          #ff005e 51%,
          #5340ff 100%
        );
        background-size: 200% auto;
        border: none;
        border-radius: 3rem;
        z-index: 90;
        cursor: pointer;
      }
      .aboutPlay:hover {
        transition: all 0.4s;
        background-position: right center;
      }
      svg {
        font-size: 22px;
      }
      .aboutImg {
        padding: 0 15px;
        margin: 0 auto;
        img {
          width: 100%;
          height: auto;
        }
      }
      .frame {
        svg {
          font-size: 42px;
          position: absolute;
          top: 8px;
          right: 20px;
          cursor: pointer;
        }
        svg:hover {
          color: #eb0b34;
        }
      }
    }
    .whatIsContent {
      margin: 0 auto;
      width: 100%;
      .whatIsHeader {
        font-size: 36px;
        font-weight: 600;
      }
      .whatIsSubHeader {
        font-size: 22px;
        font-weight: 500;
        line-height: 36px;
        text-align: left;
        margin: 1rem auto;
      }
      .marketplaceLink {
        a {
          color: $secondary;
        }
        a:hover {
          text-decoration: underline;
        }
      }
      .blockquote {
        width: 80%;
        text-align: left;
        color: $primary;
        font-style: italic;
        margin: 1rem 1rem 1rem 0;
        padding: 20px 25px;
        border-left: 5px solid $secondary;
        background: #eef2f5;
        font-size: 14px;
        line-height: 26px;
      }

      .whatIsParagraph {
        padding: 0 15px;
        text-align: left;
        line-height: 26px;
        font-size: 14px;
        margin: 1rem auto;
      }
      .aboutListBox {
        text-align: left;
        font-size: 14px;
        font-weight: 600;
        line-height: 35px;
        margin-bottom: 2rem;
        ul {
          margin-right: 1rem;
          min-width: 250px;
          li {
            width: 100%;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}

//WhatIsPromodex

@media (min-width: 812px) {
  .about {
    .whatIsContent {
      .whatIsHeader {
        font-size: 42px;
      }
      .aboutListBox {
        display: flex;
        flex-direction: row;
      }
    }
    .aboutImg {
      width: 540px;
    }
  }
}

@media (min-width: 1200px) {
  .about {
    width: 1140px;
    margin: 3rem auto;
    .whatIs {
      flex-direction: row;
      .whatIsContent {
        width: 540px;
      }
    }
    .aboutListBox {
      ul {
        margin-right: 3rem;
        min-width: 250px;
      }
    }
  }
}
